<template>
    <page-section>
        <v-container>
            <h3 class="tit text-center font-tertiary mb-40px mb-md-56px">Discover now popular fitting collections</h3>
            <v-row class="row--lg">
                <template v-for="item in items">
                    <v-col :key="item" cols="6" md="3">
                        <v-img :src="item.image" />
                        <h4 class="tit tit--xxs font-tertiary my-12px">
                            {{ item.title }}
                        </h4>
                        <p class="txt txt--sm">
                            {{ item.text }}
                        </p>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                image: "/images/shop/overview/overview-collections-slide-1.jpg",
                title: "Infinity Showers",
                text: "Qualität und Technologie in einem eleganten Spa-Design.",
            },
            {
                image: "/images/shop/overview/overview-collections-slide-2.jpg",
                title: "The Antao",
                text: "Sanfte, asymmetrische Rundungen, inspiriert von der Natur.",
            },
            {
                image: "/images/shop/overview/overview-collections-slide-3.jpg",
                title: "Loop & Friends",
                text: "Flexibilität mit verschiedenen Formen, Größen und Farben für Ihr Traumbad.",
            },
            {
                image: "/images/shop/overview/overview-collections-slide-4.jpg",
                title: "O.novo, Germany",
                text: "Modern bathroom design with attractive price-performance ratio.",
            },
        ],
    }),
};
</script>