var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('div', {
    staticClass: "grey lighten-5 py-36px py-md-72px mb-40px mb-md-80px"
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary grey lighten-5"
  }, [_vm._v("Let yourself be inspired")])])])], 1), _c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "inspiration-text"
  }, [_c('h3', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("Brushed nickel")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("More modern and more pure: Brushed nickel shows the "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" metallic look from its soft side. This finish works in contrast "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" to Chrome warm in color. It brings peace and a gentle glow "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" to the bathroom.")]), _c('p', {
    staticClass: "mt-0"
  }, [_vm._v("Brushed nickel is particularly suitable for colours from the "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" water world - from a light turquoise to anthracite green.")])])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "d-block",
      attrs: {
        "src": item,
        "alt": ""
      }
    })])];
  })], 2), _c('div', {
    staticClass: "mt-18px mt-md-24px"
  }, [_c('v-btn', {
    staticClass: "swiper-button--prev",
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1), _c('div', {
    staticClass: "swiper-pagination"
  }), _c('v-btn', {
    staticClass: "swiper-button--next",
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }