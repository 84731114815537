<template>
    <page-section>
        <div class="grey lighten-5 py-36px py-md-72px">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <h2 class="tit font-tertiary">
                            More inspiration to <br />
                            download for you
                        </h2>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-sheet color="#fff" class="pa-20px ml-20px pa-md-32px ml-md-0">
                            <v-row align="center" no-gutters>
                                <v-col cols="auto">
                                    <v-sheet width="108" height="152" class="download-image">
                                        <v-img max-width="108" src="/images/shop/overview/overview-download.jpg" />
                                    </v-sheet>
                                </v-col>
                                <v-col>
                                    <div class="txt txt--xs line-height-2">2024</div>
                                    <h3 class="tit font-tertiary mb-12px mb-md-16px">Design &amp; functionality in</h3>
                                    <p class="txt txt--xs txt--dark">Discover the new fittings of Villeroy &amp; Boch. For more information and inspiration please download the current brochure on the fittings.</p>
                                    <div class="txt txt--xs txt--dark mt-12px mt-md-16px">PDF 6 MB</div>
                                    <v-btn text class="pa-0 my-20px rounded-0 v-size--xx-small">
                                        <span class="txt txt--xs txt--dark font-weight-medium">Download now</span>
                                        <v-img src="/images/icon/icon-download.svg" class="ml-8px" />
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
};
</script>

<style lang="scss" scoped>
.download-image {
    position: relative;
    .v-image {
        position: absolute;
        top: 0;
        left: -40px;
    }
}
@media (min-width: 768px) {
    .download-image {
        .v-image {
            left: -76%;
        }
    }
}
</style>