var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('h3', {
    staticClass: "tit text-center font-tertiary mb-40px mb-md-56px"
  }, [_vm._v("Discover now popular fitting collections")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image
      }
    }), _c('h4', {
      staticClass: "tit tit--xxs font-tertiary my-12px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }