<template>
    <page-section class="grey lighten-5">
        <v-container>
            <h2 class="tit font-weight-regular font-tertiary text-center mb-18px mb-md-24px">Perfect ensemble of fittings and ceramics</h2>

            <v-row justify="center" align="center" class="row--lg">
                <v-col order-md="2" cols="12" md="auto">
                    <v-sheet max-width="700" color="transparent">
                        <swiper class="swiper" v-bind="{ options }">
                            <template v-for="item in items">
                                <swiper-slide :key="item">
                                    <img :src="item" alt="" class="d-block" />
                                </swiper-slide>
                            </template>
                        </swiper>
                        <div class="swiper-pagination" />
                    </v-sheet>
                </v-col>
                <v-col order-md="1" cols="auto">
                    <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--prev">
                        <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                    </v-btn>
                </v-col>
                <v-col order="3" cols="auto">
                    <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--next">
                        <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                    </v-btn>
                </v-col>
            </v-row>

            <p class="txt txt--sm text-center mt-24px mt-md-40px">
                For a particularly harmonious overall picture and optimal functionality, we ensure <br class="d-none d-md-block" />
                that fittings and bath ceramics perfectly match. Essential design elements of the <br class="d-none d-md-block" />
                ceramics can be found in the design of the fittings.
            </p>
        </v-container>
    </page-section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        PageSection,
    },
    data: () => ({
        items: [
            "/images/shop/overview/overview-ensemble-slide-1.jpg",
            "/images/shop/overview/overview-ensemble-slide-1.jpg",
            "/images/shop/overview/overview-ensemble-slide-1.jpg",
            //
        ],
        options: {
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.swiper-pagination {
    position: static;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    ::v-deep {
        .swiper-pagination-bullet {
            background-color: rgba(0, 0, 0, 0.2) !important;

            &-active {
                background-color: #007aff !important;
            }
        }
    }
}
</style>