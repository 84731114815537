<template>
    <page-section>
        <v-container>
            <div class="product-section-title mb-40px mb-md-80px">
                <span class="tit font-weight-regular font-tertiary white">Product range</span>
            </div>
            <v-sheet max-width="980" class="mx-auto">
                <v-row align="center" class="row--xxl ma-md-n50px">
                    <template v-for="item in items">
                        <v-col :key="item" cols="12" md="6" class="pa-md-50px">
                            <v-img max-width="438" :src="item.image" />
                            <div class="pa-16px pb-0">
                                <h3 class="tit tit--xs mb-8px">
                                    {{ item.title }}
                                </h3>
                                <p class="txt txt--sm txt--dark mb-8px">
                                    {{ item.text }}
                                </p>
                                <router-link :to="item.link" class="font-weight-medium txt txt--xs txt--dark d-flex align-center">
                                    {{ item.button }}
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </router-link>
                            </div>
                        </v-col>
                    </template>
                </v-row>
            </v-sheet>
        </v-container>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        PageSection,
    },
    data: () => ({
        items: [
            {
                image: "/images/shop/overview/overview-range-1.jpg",
                title: "Washing table fittings",
                text: "Adjustable heights for each type of installation",
                button: "To the washstand fittings",
                link: "/",
            },
            {
                image: "/images/shop/overview/overview-range-2.jpg",
                title: "Bathroom fittings",
                text: "Multiple versions for different types of installation",
                button: "To the bath fittings",
                link: "/",
            },
            {
                image: "/images/shop/overview/overview-range-3.jpg",
                title: "Accessories",
                text: "Suitable accessories such as towel racks and soap bowls",
                button: "To the accessories",
                link: "/",
            },
            {
                image: "/images/shop/overview/overview-range-4.jpg",
                title: "assortment of showers",
                text: "Hand and head showers in different designs as well as complete shower sets",
                button: "To the shower range",
                link: "/",
            },
        ],
    }),
};
</script>