<template>
    <page-section>
        <div class="grey lighten-5 py-36px py-md-72px mb-40px mb-md-80px">
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary grey lighten-5">Let yourself be inspired</span>
                </div>
            </v-container>
        </div>
        <v-container>
            <v-row align="center" class="row--lg">
                <v-col cols="12" md="5">
                    <div class="inspiration-text">
                        <h3 class="tit font-tertiary mb-12px mb-md-16px">Brushed nickel</h3>
                        <div class="txt txt--sm txt--dark">
                            <p>More modern and more pure: Brushed nickel shows the <br class="d-none d-md-block">
                            metallic look from its soft side. This finish works in contrast <br class="d-none d-md-block">
                            to Chrome warm in color. It brings peace and a gentle glow <br class="d-none d-md-block">
                            to the bathroom.</p>
                            <p class="mt-0">Brushed nickel is particularly suitable for colours from the <br class="d-none d-md-block">
                            water world - from a light turquoise to anthracite green.</p>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="7">
                    <swiper class="swiper" v-bind="{ options }">
                        <template v-for="item in items">
                            <swiper-slide :key="item">
                                <img :src="item" alt="" class="d-block" />
                            </swiper-slide>
                        </template>
                    </swiper>

                    <div class="mt-18px mt-md-24px">
                        <v-btn x-small icon outlined class="swiper-button--prev">
                            <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                        </v-btn>
                        <div class="swiper-pagination" />
                        <v-btn x-small icon outlined class="swiper-button--next">
                            <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        PageSection,
    },
    data: () => ({
        items: [
            "/images/shop/overview/overview-inspiration-slide-1.jpg",
            "/images/shop/overview/overview-inspiration-slide-2.jpg",
            "/images/shop/overview/overview-inspiration-slide-1.jpg",
            "/images/shop/overview/overview-inspiration-slide-2.jpg",
            "/images/shop/overview/overview-inspiration-slide-1.jpg",
        ],
        options: {
            allowTouchMove: true,
            spaceBetween: 56,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.inspiration-text {
    position: relative;
    z-index: 3;
}
.swiper {
    position: relative;
    &::before {
        content: "";
        width: 100vw;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: -100vw;
        z-index: 2;
    }
}
.swiper-container {
    overflow: visible;
    .swiper-wrapper {
        overflow: inherit;
    }
}
</style>