var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "product-section-title mb-40px mb-md-80px"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("Product range")])]), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "980"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl ma-md-n50px",
    attrs: {
      "align": "center"
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      staticClass: "pa-md-50px",
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-img', {
      attrs: {
        "max-width": "438",
        "src": item.image
      }
    }), _c('div', {
      staticClass: "pa-16px pb-0"
    }, [_c('h3', {
      staticClass: "tit tit--xs mb-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm txt--dark mb-8px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('router-link', {
      staticClass: "font-weight-medium txt txt--xs txt--dark d-flex align-center",
      attrs: {
        "to": item.link
      }
    }, [_vm._v(" " + _vm._s(item.button) + " "), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }