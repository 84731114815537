var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-intro__info"
  }, [_c('info-section', [_c('span', {
    staticClass: "txt txt--xs grey--text text--darken-2 line-height-2 border-b"
  }, [_vm._v("회사명")]), _c('h3', {
    staticClass: "tit font-weight-regular"
  }, [_vm._v(_vm._s(_vm.product.name) + " " + _vm._s(_vm.product.code || ""))]), _c('div', {
    staticClass: "pt-16px pt-md-24px"
  }, [_c('info-rating', _vm._b({}, 'info-rating', {
    product: _vm.product
  }, false))], 1)]), _c('info-section', [_c('info-price', _vm._b({}, 'info-price', {
    product: _vm.product
  }, false))], 1), _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('info-shipping', _vm._b({}, 'info-shipping', {
    product: _vm.product
  }, false))], 1), _c('div', {
    staticClass: "d-none d-lg-block"
  }, [_vm._t("form"), _vm._t("actions")], 2), _vm._t("foot")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }