var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "liberty-text"
  }, [_c('h3', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("The Liberty")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v(" The slightly playful geometry of round and oval elements is complemented by a sharp gradient. Liberty combines all shapes in a harmonious design that fits particularly well with the edges and roundings of the Collaro ceramics. That's why we say, Top Together. ")]), _c('v-btn', {
    staticClass: "white mt-18px mt-md-24px px-20px px-md-26px rounded-xs",
    attrs: {
      "large": "",
      "tile": "",
      "elevation": "4"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("To the Mettlach fittings")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item) {
    return [_c('swiper-slide', {
      key: item
    }, [_c('img', {
      staticClass: "d-block",
      attrs: {
        "src": item,
        "alt": ""
      }
    })])];
  })], 2), _c('div', {
    staticClass: "mt-18px mt-md-24px"
  }, [_c('v-btn', {
    staticClass: "swiper-button--prev",
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1), _c('div', {
    staticClass: "swiper-pagination"
  }), _c('v-btn', {
    staticClass: "swiper-button--next",
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }