var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/shop/overview/visual.jpg",
      "title": "BATHROOM FITTINGS"
    }
  }), _c('page-section', [_c('v-container', {
    staticClass: "mt-50px mt-md-100px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" Get to know the product range "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" Let us advise "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" Find inspiration "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('router-link', {
    staticClass: "txt txt--sm d-flex align-center",
    attrs: {
      "to": "#2"
    }
  }, [_vm._v(" Discover collections "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-down.svg"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('overview-ensemble'), _c('overview-range'), _c('page-section', [_c('v-img', {
    attrs: {
      "src": "/images/shop/overview/overview-wide.jpg"
    }
  })], 1), _c('overview-inspiration'), _c('overview-download'), _c('page-section', [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("Which style suits you?")]), _c('p', {
    staticClass: "txt txt--sm px-md-28px"
  }, [_vm._v("How do you imagine your washing area, shower or bathing paradise: with a strikingly angular design or in a minimalist style? Our fitting collections offer you a special freedom of design. And are perfectly matched to the Villeroy & Boch bath collections.")])])], 1)], 1)], 1), _c('overview-liberty'), _c('page-section', [_c('v-sheet', {
    attrs: {
      "color": "grey lighten-5 py-36px py-md-72px"
    }
  }, [_c('v-container', [_c('h2', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("Whether classic-elegant, puristic or modern")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Our fittings collections are always at the heart of the times. For every style and every need the right one.")])])], 1)], 1), _c('overview-collections')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }