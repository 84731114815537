<template>
    <client-page>
        <shop-product-visual wide image="/images/shop/overview/visual.jpg" title="BATHROOM FITTINGS"></shop-product-visual>

        <page-section>
            <v-container class="mt-50px mt-md-100px">
                <v-row>
                    <v-col cols="12" md="auto">
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            Get to know the product range
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            Let us advise
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            Find inspiration
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            Discover collections
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <overview-ensemble />
        <overview-range />

        <page-section>
            <v-img src="/images/shop/overview/overview-wide.jpg" />
        </page-section>

        <overview-inspiration />
        <overview-download />

        <page-section>
            <v-container class="text-center">
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <h2 class="tit font-tertiary mb-12px mb-md-16px">Which style suits you?</h2>
                        <p class="txt txt--sm px-md-28px">How do you imagine your washing area, shower or bathing paradise: with a strikingly angular design or in a minimalist style? Our fitting collections offer you a special freedom of design. And are perfectly matched to the Villeroy &amp; Boch bath collections.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <overview-liberty />

        <page-section>
            <v-sheet color="grey lighten-5 py-36px py-md-72px">
                <v-container>
                    <h2 class="tit font-tertiary mb-12px mb-md-16px">Whether classic-elegant, puristic or modern</h2>
                    <p class="txt txt--sm">Our fittings collections are always at the heart of the times. For every style and every need the right one.</p>
                </v-container>
            </v-sheet>
        </page-section>

        <overview-collections />
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import OverviewEnsemble from "@/components/client/shop/products/overview/overview-ensemble.vue";
import OverviewRange from "@/components/client/shop/products/overview/overview-range.vue";
import OverviewInspiration from "@/components/client/shop/products/overview/overview-inspiration.vue";
import OverviewDownload from "@/components/client/shop/products/overview/overview-download.vue";
import OverviewLiberty from "@/components/client/shop/products/overview/overview-liberty.vue";
import OverviewCollections from "@/components/client/shop/products/overview/overview-collections.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        OverviewEnsemble,
        OverviewRange,
        OverviewInspiration,
        OverviewDownload,
        OverviewLiberty,
        OverviewCollections,
        ShopProductVisual,
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>