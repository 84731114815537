<template>
    <page-section>
        <v-container>
            <v-row align="center" class="row--lg">
                <v-col cols="12" md="5">
                    <div class="liberty-text">
                        <h3 class="tit font-tertiary mb-12px mb-md-16px">The Liberty</h3>
                        <p class="txt txt--sm txt--dark">
                            The slightly playful geometry of round and oval elements is complemented by a sharp gradient. Liberty combines all shapes in a harmonious design that fits particularly well with the edges and roundings of the Collaro ceramics. That's why we say, Top Together.
                            <!-- <br class="d-none d-md-block" /> -->
                        </p>
                        <v-btn large tile elevation="4" class="white mt-18px mt-md-24px px-20px px-md-26px rounded-xs">
                            <span class="txt txt--xs txt--dark font-weight-medium">To the Mettlach fittings</span>
                            <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="7">
                    <swiper class="swiper" v-bind="{ options }">
                        <template v-for="item in items">
                            <swiper-slide :key="item">
                                <img :src="item" alt="" class="d-block" />
                            </swiper-slide>
                        </template>
                    </swiper>

                    <div class="mt-18px mt-md-24px">
                        <v-btn x-small icon outlined class="swiper-button--prev">
                            <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                        </v-btn>
                        <div class="swiper-pagination" />
                        <v-btn x-small icon outlined class="swiper-button--next">
                            <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </page-section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        PageSection,
    },
    data: () => ({
        items: [
            "/images/shop/overview/overview-liberty-slide-1.jpg",
            "/images/shop/overview/overview-liberty-slide-2.jpg",
            "/images/shop/overview/overview-liberty-slide-1.jpg",
            "/images/shop/overview/overview-liberty-slide-2.jpg",
            "/images/shop/overview/overview-liberty-slide-1.jpg",
            //
        ],
        options: {
            allowTouchMove: true,
            spaceBetween: 56,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.liberty-text {
    position: relative;
    z-index: 3;
}
.swiper {
    position: relative;
    &::before {
        content: "";
        width: 100vw;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: -100vw;
        z-index: 2;
    }
}
.swiper-container {
    overflow: visible;
    .swiper-wrapper {
        overflow: inherit;
    }
}
</style>